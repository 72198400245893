a {
  &:focus {
    outline: none;
  }
}

a:not([href]) {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

h2 {
    font-size: 1.42857143rem; // 20px
    font-weight: 500;
    line-height: 1.71428571rem; // 24px
    color: #48484A;
    margin-bottom: 0;
}

h3 {
    font-size: 1.28571429rem; // 18px
    font-weight: 500;
    line-height: 1.57142857rem; // 22px
    color: #48484A;
    margin-bottom: 0;
}
